<template>
  <div class="research-details">
    <Loader v-if="isLoading" />
    <div v-else-if="data && tableTabs">
      <vs-popup title="Lorem ipsum dolor sit amet" :active.sync="cellValues">
        <vs-chip v-for="(value, index) in cellValues" :key="index">
          {{ value }}
        </vs-chip>
      </vs-popup>
      <div style="text-align: right !important; display: flex">
        <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-edit"
          @click="onEditClick"
        >
          {{ $l.get("dictionary.edit") }}
        </vs-button>
        <vs-button
          type="border"
          icon="cached"
          style="margin-left: 5px"
          @click="onRefreshClick"
        >
          {{ $l.get("dictionary.refresh") }}
        </vs-button>
        <vs-button
          v-if="!isProcessing"
          style="margin-left: 5px"
          type="border"
          icon-pack="feather"
          icon="icon-cloud-lightning"
          @click="onProcessClick"
        >
          {{ $l.get("dictionary.process") }}
        </vs-button>
      </div>
      <br />
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.volunteers") }}</h3>
        </div>
        <p>
          {{ data.research.volunteersTotal }} /
          {{ data.research.samplesRequired }}
        </p>
        <vs-progress
          :percent="data.research.volunteersPercent"
          color="primary"
          >{{ data.research.volunteersPercent }}</vs-progress
        >
      </vs-card>
      <br />
      <vs-card v-if="isProcessing">
        <div slot="header">
          <h3>{{ $l.get("dictionary.progress") }}</h3>
        </div>
        <vs-progress :percent="data.research.percentDone" color="primary">{{
          data.research.percentDone
        }}</vs-progress>
      </vs-card>
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.results") }}</h3>
        </div>
        <p style="text-align: center">
          {{ $l.get("research-details.population") }}
        </p>
        <vs-input-number v-model="population" />
        <br />
        <p style="text-align: center">
          {{ $l.get("research-details.reliability") }}
        </p>
        <ul class="centerx">
          <li>
            <vs-radio
              color="primary"
              v-model="reliability"
              vs-name="reliability"
              vs-value="128"
              >80%</vs-radio
            >
          </li>
          <li>
            <vs-radio
              color="primary"
              v-model="reliability"
              vs-name="reliability"
              vs-value="144"
              >85%</vs-radio
            >
          </li>
          <li>
            <vs-radio
              color="primary"
              v-model="reliability"
              vs-name="reliability"
              vs-value="164"
              >90%</vs-radio
            >
          </li>
          <li>
            <vs-radio
              color="primary"
              v-model="reliability"
              vs-name="reliability"
              vs-value="196"
              >95%</vs-radio
            >
          </li>
          <li>
            <vs-radio
              color="primary"
              v-model="reliability"
              vs-name="reliability"
              vs-value="258"
              >99%</vs-radio
            >
          </li>
        </ul>
        <br />
        <vs-tabs v-model="tableTabIndex">
          <vs-tab
            v-for="(label, index) in tableTabs"
            :key="index"
            :label="label"
          >
            <vs-table v-if="data" :data="data.results.results">
              <template slot="thead">
                <vs-th>{{ $l.get("research-details.area-field") }}</vs-th>
                <vs-th
                  v-for="(col, index) in data.results.columns"
                  :key="index"
                >
                  {{ getColumnLabel(col) }}
                  <br />n (%)
                </vs-th>
                <vs-th>
                  {{ $l.get("research-details.sample-field") }}
                  <br />n
                </vs-th>
                <vs-th>
                  {{ $l.get("research-details.margin-of-error-field") }}
                  <br />%
                </vs-th>
              </template>
              <template>
                <vs-tr
                  :key="rowIndex"
                  v-for="(row, rowIndex) in data.results.rows"
                >
                  <vs-td>{{ row.label.toUpperCase() }}</vs-td>
                  <vs-td
                    :key="colIndex"
                    v-for="(col, colIndex) in data.results.columns"
                  >
                    <vx-tooltip :text="getCellLabel(rowIndex, colIndex)">
                      <div>
                        <div
                          v-if="getCellMultichoicesLabel(rowIndex, colIndex)"
                          style="color: #999999"
                        >
                          {{ getCellMultichoicesLabel(rowIndex, colIndex) }}
                        </div>
                        <a :href="getDownloadLink(rowIndex, colIndex)" download>
                          <b>{{ getValue(rowIndex, colIndex) }}</b>
                        </a>
                        <br />
                        {{ data.results.results[rowIndex][colIndex][4] }}
                      </div>
                    </vx-tooltip>
                  </vs-td>
                  <vs-td>{{ getSampleSize(rowIndex) || "-" }}</vs-td>
                  <vs-td>{{ getMarginOfError(rowIndex) || "-" }}%</vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td></vs-td>
                  <vs-td
                    :key="colIndex"
                    v-for="(col, colIndex) in data.results.columns"
                  ></vs-td>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td>
                    <b>{{ $l.get("dictionary.average", "upper") }}</b>
                  </vs-td>
                  <vs-td
                    :key="colIndex"
                    v-for="(col, colIndex) in data.results.columns"
                  >
                    <b>{{ data.averages[colIndex][getValuePosition()] }}</b>
                  </vs-td>
                  <vs-td>
                    <b>{{ data.averages[data.results.columns.length][4] }}</b>
                  </vs-td>
                  <vs-td>
                    <b>
                      {{
                        calculateMarginOfError(
                          data.averages[data.results.columns.length][4]
                        )
                      }}%
                    </b>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-tab>
        </vs-tabs>
      </vs-card>
    </div>
  </div>
</template>

<script>
import SelectResearchEditInteractor from "@/business/research-details/select-research-edit";
import SelectResearchProcessInteractor from "@/business/research-details/select-research-process";
import SelectCellInteractor from "@/business/research-details/select-cell";
import InitResearchDetailsInteractor from "@/business/research-details/init-research-details";
import ResearchDetailsScreenController from "@/adapters/controllers/screen-research-details";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "research-details",
  components: { Loader },
  data() {
    const txtPhase = this.$l.get("dictionary.phase");
    const txtPositive = this.$l.get("research-details.tab-positive");
    const txtNegative = this.$l.get("research-details.tab-negative");
    const txtDual = this.$l.get("research-details.tab-dual");
    return {
      controller: null,
      interactors: {
        selectResearchEdit: null,
        selectResearchProcess: null,
        selectCell: null,
        initResearchDetails: null
      },
      data: null,
      isLoading: false,
      population: 100000,
      reliability: "196",
      tableTabs: [
        `${txtPhase} #1: ${txtPositive}`,
        `${txtPhase} #1: ${txtNegative}`,
        `${txtPhase} #1: ${txtDual}`,
        `${txtPhase} #2: ${txtPositive}`,
        `${txtPhase} #2: ${txtNegative}`,
        `${txtPhase} #2: ${txtDual}`,
        `${txtPhase} #3: ${txtPositive}`,
        `${txtPhase} #3: ${txtNegative}`,
        `${txtPhase} #3: ${txtDual}`
      ],
      tableTabIndex: 0,
      cellValues: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(ResearchDetailsScreenController);

    //{ INTERACTORS
    this.interactors.initResearchDetails = this.$injector.get(
      InitResearchDetailsInteractor
    );
    this.interactors.selectCell = this.$injector.get(SelectCellInteractor);
    this.interactors.selectResearchProcess = this.$injector.get(
      SelectResearchProcessInteractor
    );
    this.interactors.selectResearchEdit = this.$injector.get(
      SelectResearchEditInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
    this.controller.showCellResults = this.showCellResults;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initResearchDetails.handle(id);
  },
  computed: {
    isStopped() {
      return this.data && this.data.research.tasksCount == 0;
    },
    isProcessing() {
      return (
        this.data &&
        this.data.research.percentDone < 100 &&
        this.data.research.tasksCount > 0
      );
    },
    isDone() {
      return this.data && this.data.research.percentDone >= 100;
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onRefreshClick() {
      const id = this.$route.params.id;
      this.interactors.initResearchDetails.handle(id);
    },
    onProcessClick() {
      const id = this.$route.params.id;
      this.interactors.selectResearchProcess.handle(id);
    },
    onEditClick() {
      const id = this.$route.params.id;
      this.interactors.selectResearchEdit.handle(id);
    },
    getColumnLabel(col) {
      switch (col["type"]) {
        case 1:
          return this.$l.get("research-details.desire-field", "upper");
        case 2:
          return this.$l.get("research-details.satisfaction-field", "upper");
        case 3:
          return this.$l.get("research-details.frustration-field", "upper");
        case 4:
          return this.$l.get("research-details.dissatisfaction-field", "upper");
        case 5:
          return this.$l.get("research-details.active-field", "upper");
        case 6:
          return this.$l.get("research-details.passive-field", "upper");
        case 7:
          return this.$l.get("research-details.pleasant-field", "upper");
        case 8:
          return this.$l.get("research-details.unpleasant-field", "upper");
        case 9:
          return this.$l.get("research-details.neutral-field", "upper");
        default:
          return "";
      }
    },
    getValuePosition() {
      switch (this.tableTabIndex) {
        case 0: // P#1: Positives
          return 1;
        case 1: // P#1: Negatives
          return 2;
        case 2: // P#1: Dual
          return 3;

        case 3: // P#2: Positives
          return 6;
        case 4: // P#2: Negatives
          return 7;
        case 5: // P#2: Dual
          return 8;

        case 6: // P#3: Positives
          return 10;
        case 7: // P#3: Negatives
          return 11;
        case 8: // P#3: Dual
          return 12;
      }
      return 0;
    },
    calculatePercent(rowIndex, colIndex) {
      const totalIndex = this.getSampleSize(rowIndex);
      const total = this.data.results.results[rowIndex][totalIndex];
      const value = this.data.results.results[rowIndex][colIndex];
      const pos = this.getValuePosition();
      if (total == 0) {
        return 0;
      } else {
        return 100 * (value[pos] / total[pos]);
      }
    },
    getSampleSize(rowIndex) {
      const totalIndex = this.data.results.columns.length;
      const entry = this.data.results.results[rowIndex][totalIndex];
      // const pos = this.getValuePosition();
      return entry[4];
    },
    calculateMarginOfError(sampleSize) {
      const p = 1.0 / 2.0;
      const rp = p * (1 - p);
      const reliability = parseInt(this.reliability) / 100.0;
      const t1 = this.population * Math.pow(reliability, 2.0) * rp;
      const t2 = sampleSize * Math.pow(reliability, 2.0) * rp;
      const t3 = sampleSize * (this.population - 1.0);
      const value = Math.round(100.0 * Math.sqrt((t1 - t2) / t3));
      if (value && isFinite(value)) {
        return value;
      } else {
        return null;
      }
    },
    getMarginOfError(rowIndex) {
      const sampleSize = this.getSampleSize(rowIndex);
      return this.calculateMarginOfError(sampleSize);

      // const pos = this.getValuePosition();
      // return entry[pos];
    },
    getValue(rowIndex, colIndex) {
      const entry = this.data.results.results[rowIndex][colIndex];
      const pos = this.getValuePosition();
      return entry[pos];
    },
    getDownloadLink(rowIndex, colIndex) {
      const gateway = this.$injector.get("ResearchGateway");
      const id = this.$route.params.id;
      const cellId = this.data.cellsIds[rowIndex][colIndex];

      let type = 0;
      switch (this.tableTabIndex) {
        case 0: // P#1: Positives
          type = 100;
          break;
        case 1: // P#1: Negatives
          type = 101;
          break;
        case 2: // P#1: Dual
          type = 102;
          break;

        case 3: // P#2: Positives
          type = 200;
          break;
        case 4: // P#2: Negatives
          type = 201;
          break;
        case 5: // P#2: Dual
          type = 202;
          break;

        case 6: // P#3: Positives
          type = 300;
          break;
        case 7: // P#3: Negatives
          type = 301;
          break;
        case 8: // P#3: Dual
          type = 302;
          break;
      }

      return `${gateway.URL}/${id}/download?cell=${cellId}&type=${type}`;
    },
    onSelectCell(rowIndex, colIndex) {
      const id = this.$route.params.id;
      const cellId = this.data.cellsIds[rowIndex][colIndex];
      let type = 0;
      switch (this.tableTabIndex) {
        case 0: // P#1: Positives
          type = 100;
          break;
        case 1: // P#1: Negatives
          type = 101;
          break;
        case 2: // P#1: Dual
          type = 102;
          break;

        case 3: // P#2: Positives
          type = 200;
          break;
        case 4: // P#2: Negatives
          type = 201;
          break;
        case 5: // P#2: Dual
          type = 202;
          break;

        case 6: // P#3: Positives
          type = 300;
          break;
        case 7: // P#3: Negatives
          type = 301;
          break;
        case 8: // P#3: Dual
          type = 302;
          break;
      }
      this.interactors.selectCell.handle(id, cellId, type, 0, 100);
    },
    showCellResults(values) {
      this.cellValues = values;
    },
    getCellLabel(rowIndex, colIndex) {
      const label = this.data.cellsLabels[rowIndex][colIndex];
      if (label) return label;

      const row = this.data.results.rows[rowIndex].label;
      const col = this.getColumnLabel(this.data.results.columns[colIndex]);
      return `${row} + ${col}`;
    },
    getCellMultichoicesLabel(rowIndex, colIndex) {
      return this.data.cellsMultichoicesLabels[rowIndex][colIndex];
    }
  }
};
</script>

<style lang="scss"></style>
